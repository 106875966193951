@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
:root{
  --orange-darken: #f15921;
  --blue-darken:#010a17;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 300;
  line-height: 25px;
  font-size: 14px !important;
}

.mt-7{
  margin-top: 7rem !important;
}
.content {
  font-family: 'DM Sans', sans-serif !important;
  white-space: nowrap !important;
}

.modal {
  height: max-content !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  outline: 0 !important;
  display: block !important;
  position: relative !important;
}

ul li,
span {
  white-space: nowrap !important;
}

.react-bs-table-csv-btn {
  background-color: #2F3640 !important;
  outline: none !important;
  border: none !important;
  border-color: #ffffff !important;
}

/* body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background: #241e20;
}

body::-webkit-scrollbar-thumb {
  background: #f15921;
  border-radius: 4px;
} */

::selection {
  background: #f15921 !important;
  color: #ffffff !important;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration-color: none !important;
  text-decoration: none !important;
  outline: none;
  color: inherit;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 300;
}

.list-active {
  background-color: #ffffff !important;
}

.active-teal {
  color: #010a17 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-color: #fff !important;
}

.btn-primary:hover,
.btn-primary:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}

.text-grey {
  color: #ccc !important;
}

.text-chocolate {
  color: #563636 !important;
}

.bg-chocolate {
  background-color: #563636 !important;
}

.text-blue-darken {
  color: #010a17 !important;
}

.bg-blue-darken {
  background-color: #010a17 !important;
}

.text-orange-darken {
  color: #f15921 !important
}

.bg-orange-darken {
  background-color: #f15921 !important
}

.text-yellow-darken{
  color: #FBBD08 !important;
}

.bg-yellow-darken{
  background-color: #FBBD08 !important;
}

.bg-blue-sky{
  background-color: #03A9F4 !important;
}

.text-blue-sky{
  color: #03A9F4 !important;
}

.user-select-none{
  user-select: none;
}

.bg-gradient-0{
  background-image: linear-gradient(to left top, #f15921, #d64f2e, #ba4836, #9d423a, #7f3d3a, #713b39, #643838, #563636, #563636, #563636, #563636, #563636);
}
