
.App {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  background-color: #fff !important;
}

#search-bar-0-label {
  display: none !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.overflowX {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
}

.fdiBalance-money {
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column !important;
}

/* Start of Notification messages */
.notification-show {
  margin-right: 10px !important;
  position: relative;
}

#notification-bell {
  font-size: 30px;
  margin-right: 8px !important;
}

.rating-book-label>.rating-book-radio {
  display: none;
}

.rating-book-label>.star {
  font-size: 25px;
  text-align: center;
}

.notification-column-not-opened {
  cursor: pointer;
  margin-bottom: 2px;
}

.notification-column-not-opened:hover {
  border-bottom: 1px dotted #ff6600;
}

.notification-column-opened {
  /* background-color: #eeeeee; */
  padding: 12px;
  width: 100%;
  font-size: 18px;
  /* font-weight: bold; */
  border-bottom: 1px dotted black;
}

.deleteNotification {
  color: white !important;
  background-color: brown !important;
  border: none !important;
}

.notification-column-opened {
  cursor: pointer;
  color: rgb(224, 215, 215) !important;
}

.image-book {
  width: 140px;
  height: 173px;
}

.image-book-text {
  max-width: 223px;
  font-weight: 300;
}

@media (max-width:980px) {
  .hide-navbar,.hide-navbar-icon {
    display: none;
  }
  .hide-navbar-icon1{
    display: inline;
    cursor: pointer;
  }
}
@media (min-width:980px) {
  .hide-navbar-icon {
    display: inline;
  }
  .hide-navbar-icon1{
    display: none;
    cursor: pointer;
  }
}
/* End of Notification messages */

@media (max-width: 575.98px) {
  .on-mobile-hide {
    display: none !important;
  }

  .all-text-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .image-book {
    width: 130px !important;
    height: 155px !important;
    font-size: 10px !important;
  }

  .image-book-text {
    max-width: 175px;
  }

  .font-10 {
    font-size: 10px !important;
  }
  
  .font-11 {
    font-size: 11px !important;
  }

  .no-white-space {
    white-space: nowrap !important;
  }

  .form-container {
    width: 90%;
    padding: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 10px 20px #0000004d;
  }

  .mobile-no-margin-right {
    margin-right: 0rem !important;
  }

  .mobile-no-margin {
    margin: 0rem !important;
  }

  .mobile-no-width {
    width: 100% !important;
  }

  .mobile-no-width-70 {
    width: 70% !important;
  }

  .mobile-width-100 {
    font-size: 1em !important;
    width: 100% !important;
  }
}

@media (min-width: 575.98px) {
  
  .form-container {
    width: 600px;
    padding: 20px;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 10px 20px #0000004d;
  }
}

.header1 {
  /* margin-bottom: 30px; */
  background-color: #37302d;
  background-image: linear-gradient(to top, #0c0c0c, #563636);
  /* margin-bottom: 20px; */
  color: #fff;
  height: 120px;
}

th {
  white-space: nowrap;
}

tbody {
  vertical-align: middle !important;
}

tbody tr td {
  white-space: nowrap;
}

.footer1 {
  margin-top: 20px;
  padding: 20px;
  background-color: #2e2e2e;
  background-image: linear-gradient(to bottom, #101010, #2b2020);
}

#Button {
  background-color: #d65526;
  color: #fff;
  width: 100%;
  border-radius: 1px;
  font-size: 14px;
  font-weight: 600 !important;
  /* text-transform: uppercase !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

#Button:hover,
#Button:focus {
  background-color: #f15921;
}

#header-link-item {
  font-size: 18px !important;
}

#header-link-item>.menu>a>.item:hover {
  color: #f15921 !important;
}




.item {
  color: rgb(238, 238, 228) !important;
  font-size: 16px;
  font-weight: normal !important;
}

.item:hover {
  color: white !important;
}

.is-active {
  border-bottom: 4px solid #f15921 !important;
  font-weight: bold !important;
  color: white !important;
}

.footerGrid {
  margin-top: 30px;
}

.footerGrid>p>a {
  font-size: 16px;
  color: rgb(238, 238, 228) !important;
  margin-bottom: 2px;
  padding: 5px;
}

.footerGrid>p>a:hover {
  color: #f15921 !important;
  transition: 0.2s ease-in-out;
  /* border-bottom: 2px solid #f15921 !important; */
}

.termsPrivacy {
  margin-right: 30px;
}

.termsPrivacy>a {
  color: #d6cece;
  margin-left: 20px;
}

.termsPrivacy>a:hover {
  color: white !important;
}

/* .container {
  width: 76%;
  margin: 4.5% 10.5% 4.5% 10.5%;
} */

.companyName {
  font-weight: bold;
  color: black;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f15921;
  border-color: gray transparent #f15921 transparent;
  animation: lds-dual-ring 0.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.tag-div {
  display: inline-block;
  background-color: #563636;
  color: #fff;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.tag {
  height: auto;
  margin-bottom: 10px;
}

/* second menu bar by Dom58 */
.second-menuBar {
  margin-top: -10px;
}

.item>a,
.item>b {
  font-size: 16px;
}

#second-navbar {
  margin-left: 40% !important;
}

#shopping {
  font-size: 40px !important;
}

.sidebar {
  font-size: 25px !important;
}

.dropdown {
  font-size: 16px !important;
}

#home-row {
  height: 550px;
  margin-bottom: 30px;
}

.author-card {
  background-color: #fff !important;
  box-shadow: #575a5a 0px 3px 10px !important;
}

.card {
  display: inline-block !important;
}

.card:hover {
  transform: scale(1.019);
  transition: transform 0.6s ease-in-out;
  cursor: pointer;
}

.image-cover {
  height: 250px !important;
  width: 100%;
  object-fit: cover;
}

.book-header {
  height: auto;
  width: 100%;
  background-color: #17616d;
}

.book-header>.book-header-left {
  width: 48%;
  margin-left: 1%;
}

.book-header-left,
.book-header-right {
  display: inline-block;
  position: relative;
  text-orientation: upright;
}

.book-header-left {
  width: 45%;
}

.book-header-right {
  width: 50%;
  top: 0 !important;
  line-height: 1px;
  float: right;
}

.book-header-second>.book-header-left {
  width: 48%;
  margin-left: 2%;
}

.image-cover-one {
  height: 440px !important;
  width: 100%;
  object-fit: fill;
  box-shadow: 0 5px 7px 5px #ccc;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 40px;
}

.text-summary {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

#pdf-download {
  display: none !important;
}

.book-header-right>.book-summary {
  padding-right: 50px;
}

.Share-icon {
  margin-left: 10%;
}

.icon {
  cursor: pointer;
}

.book-price-container {
  background-color: #fff;
  height: auto;
  width: 100%;
}

.book-price-content {
  margin: 50px;
  text-align: center;
}

#ButtonBuy {
  background-color: #ff4500;
  color: #fff;
  width: 40%;
  border-radius: 50px;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 50px;
}

#ButtonCart {
  background-color: #fff !important;
  border: 1px solid #ff4500;
  color: black;
  width: 40%;
  border-radius: 50px;
  font-size: 16px;
  margin-right: 10px;
}

#ButtonCart:hover,
#ButtonCartHome:hover {
  background-color: #ff4500 !important;
  color: #fff;
}

.book-summary-container {
  width: 100%;
}

.book-summary-content {
  width: 85%;
  text-align: center;
  margin-left: 6%;
  margin-bottom: 30px;
}

#more>.item {
  color: black !important;
  padding: 5px;
  font-size: 25px;
  margin-right: 20px;
}

#username {
  text-transform: capitalize;
}

.tags-map {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tags-map>a {
  font-size: 1.1rem;
  font-weight: bold;
  display: inline-block;
  color: #f2711c;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: rgb(255, 255, 255);
}

.tags-map>a:hover {
  background-color: #c5491b;
  color: #fff;
}

.tags-on-profile {
  margin-bottom: 10px;
}

.tags-on-profile>a {
  font-size: 14px;
  display: inline-block;
  color: #fff;
  padding: 3px;
  border-radius: 5px;
  background-color: #f2711c;
  text-align: center;
}

.tags-on-profile>a:hover {
  background-color: #c5491b;
  color: #fff;
}

a>.item {
  color: black !important;
}

a>.item:hover {
  color: black !important;
  font-weight: normal;
  font-size: 16px;
}

.right-auth-menu {
  background-color: #101010 !important;
  background-image: linear-gradient(to bottom, #101010, #2b2020) !important;
  border: 3px solid #eeeeee !important;
  width: 300px;
  margin-top: 8px !important;
}

.right-auth-menu>a>.item {
  color: white !important;
  margin-bottom: 2px !important;
  border-bottom: 1px dashed rgb(54, 54, 54) !important;
}

.right-auth-menu>a>.item:hover {
  background-color: #ff4500 !important;
}

.right-auth-menu>#logout {
  color: white !important;
  margin-bottom: 2px !important;
}

.right-auth-menu>#logout:hover {
  background-color: #ff4500 !important;
}

#flag:hover {
  cursor: pointer;
}

.Share-icon>.icon {
  font-size: 40px;
}

.container-single-book {
  width: 90%;
  margin-left: 4.577%;
  margin-right: 4.577%;
}

label {
  font-size: 16px !important;
}

.count-cart-number {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #f15921;
  border-radius: 100%;
  text-align: center;
  margin-bottom: -48px;
  box-shadow: 0px 1px 6px 0px #f15921;
  margin-top: 10px;
  margin-left: 9%;
}

.count-cart-number>p {
  color: white;
  font-size: 18px;
  line-height: 40px;
}

#remove-cart {
  border: 1px solid #f15921;
  background-color: white;
  color: #000;
  margin-top: 10px;
  padding: 8px;
}

#Buy-cart,
#remove-cart {
  display: inline-block;
  margin: 10px;
}

#remove-cart:hover {
  border: 1px solid rgb(187, 11, 11);
  background-color: rgb(165, 25, 25);
  color: white;
}

#Buy-cart {
  background-color: #f15921;
  color: white;
  padding: 8px;
  border: none;
  cursor: pointer;
}

.cart-row-container {
  background-color: white;
  margin-top: 30px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px gray;
  width: 80%;
  margin-left: 10%;
}

/* ========Start of Admin Dashboard ======= */
.dash-container {
  background-color: white;
  color: black !important;
  width: 100%;
  padding: 5px;
}

.left-container {
  padding: 10px 10px 10px 1.2rem;
  text-align: center;
  background-color: #eeeeee;
}

#dash-card-link {
  color: #000 !important;
  display: flex;
  font-size: 15px;
  font-weight: 600;
}

#dash-card-link:hover {
  /* color: #0077ff; */
  color: #f15921 !important;
}

#dashbCardUser:hover {
  background-color: #ecc4b6 !important;
  color: white !important;
  margin-right: 20px;
}

#dashbCardBook:hover {
  background-color: #e4ae9b !important;
  color: white !important;
  margin-left: 20px;
}

/* ========End of Admin Dashboard ======= */

#content {
  position: relative;
  max-height: 150px !important;
  margin-top: 5px;
}

.card-mage-cover {
  position: relative;
  height: 200px !important;
  width: 100%;
  object-fit: cover;
  margin-top: -80px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px gray;
}

#ButtonCartHome {
  background-color: #fff !important;
  border: 1px solid #ff4500;
  color: #ff4500;
  margin-right: 10px;
  float: left;
  position: relative;
}

#ButtonBuyHome {
  background-color: #ff4500 !important;
  color: #fff;
  float: right;
}

#seeAllBooks {
  background-color: #757272;
  border-radius: 2rem;
  color: #fff;
  height: 3rem;
  width: 10rem;
  font-size: 13px;
  padding: 10px;
  text-align: center;
  transition: all 0.3s;
}

#seeAllBooks:hover {
  background-color: #136d91;
  width: 11.5rem;
  text-align: center;
  font-size: 15px;
  /* height: 3.1rem; */
  transition: all 0.2s;
}

#homeCard {
  box-shadow: 0px 1px 8px 0px gray;
  width: 100%;
  border-radius: 10px;
  margin: 20px;
}

#three-columns {
  border: none;
  margin-top: 30px;
}

.left-card {
  width: 45%;
  float: left;
}

.right-card {
  width: 50%;
  float: left;
  margin-left: 8px;
}

.right-card .header,
.right-card .companyName,
.right-card .meta {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bestSellers {
  background-color: rgb(250, 249, 249);
  padding: 2px;
}

/* Audace Button-Customization */
.buy-now {
  border: 3px solid #ff4500;
  border-radius: 0;
  color: #fff;
  background-color: #ff4500;
}

.add-to-cart {
  border: 3px solid #ff4500;
  border-radius: 0;
  background-color: #fff;
  color: orangered;
  margin-right: 20px;
}

.circle-btn {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.circle-btn:hover {
  box-shadow: 0 2px 3px #2e2e2e;
}

.facebook-txt {
  font-size: 2.1em;
  font-weight: bold;
  color: #0a55ff;
}

.twitter-txt {
  font-size: 2.1em;
  font-weight: bold;
  color: #5ecefa;
}

.whatsapp-txt {
  font-size: 2.3em;
  font-weight: bold;
  color: #34d20d;
}

.email-txt {
  font-size: 2em;
  font-weight: bold;
  color: #ff4500;
}

.search-part {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.search-input {
  width: 500px !important;
  border-radius: 50px;
  outline: none;
  box-shadow: rgb(204, 204, 204) 0px 2px 6px;
}

#btn_phone_btn {
  display: none;
}

#phone_covers {
  display: none;
}

#footer-language-show {
  display: none;
}

#phone-header-language-show {
  display: none;
}

.saveAsButtons {
  margin-left: 40%;
  margin-top: -70px;
}

.single-book-tags-content {
  margin-bottom: 17px;
  text-align: center;
}

.single-book-tag {
  background-color: rgb(240, 221, 204);
  padding: 8px;
  margin-right: 3px;
  color: black !important;
}

.single-book-tag:hover {
  background-color: rgb(241, 207, 174);
}

#mobile-app {
  width: 190px;
  min-width: 190px;
}

#mobile-app:hover {
  opacity: 0.8;
  transition: 0.4s ease-in-out;
}

/* Sample of Resposiviness */

/* medium screen */
@media only screen and (min-width: 776px) and (max-width: 999px) {
  .header1 {
    /* margin-bottom: 30px; */
    background-color: #37302d;
    background-image: linear-gradient(to top, #0c0c0c, #563636);
    /* margin-bottom: 20px; */
    color: #fff;
    height: 100px;
    width: auto !important;
    display: block;
    position: flex;
    padding: 5px;
  }

  #logo-header {
    width: 60px !important;
  }

  #header-link-item {
    font-size: 18px !important;
    margin-left: -30px !important;
  }

  #header-link-item>.menu>a>.item:hover {
    color: #f15921 !important;
  }

  .dropdown {
    font-size: 16px !important;
  }

  #logout {
    font-size: 16px !important;
  }

  #home-row {
    height: auto;
    margin-bottom: 50px;
    display: block;
  }



  input[type='text']::placeholder {
    color: #d6896d;
    font-size: 14px;
  }

  /* .SelectLanguage {
    display: none !important;
  } */

  #footer-language-show {
    display: block !important;
  }

  #phone-header-language-show {
    display: block !important;
    margin-top: -11px !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #right-auth {
    margin-right: 10px !important;
  }

  #profile-picture {
    width: 40px !important;
    height: 40px !important;
  }

  .circle-btn {
    width: 28px;
    height: 28px;
  }

  .cartBadge {
    background-color: #f15921;
    width: 25px !important;
    height: 25px !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: -35px;
    margin-left: -30px;
    z-index: 1;
    cursor: pointer;
  }

  .cartCount {
    font-size: 14px;
    color: white;
  }

  .item {
    color: rgb(238, 238, 228) !important;
    font-size: 12px;
    font-weight: normal !important;
  }

  .item>a,
  .item>b {
    font-size: 16px !important;
  }

  .item>a,
  .item>b {
    font-size: 16px;
  }

  a>.item {
    color: black !important;
    font-weight: normal;
    font-size: 14px;
  }

  a>.item:hover {
    color: black !important;
    font-weight: normal;
    font-size: 14px;
  }

  .right-auth-menu>a>.item {
    color: white !important;
    margin-bottom: 2px !important;
  }

  #underHeader {
    width: 90% !important;
    margin: 2% 0 0% 4.3% !important;
  }

  #covers {
    display: none;
  }

  #phone_covers {
    display: block;
    width: 100% !important;
    object-fit: cover;
    margin-bottom: 30px !important;
    /* box-shadow: 0px 0px 5px 2px gray; */
  }

  .slogans {
    margin-top: 5px !important;
    display: block !important;
    width: 100% !important;
  }

  .slogans>h1 {
    font-size: 23px;
    margin-bottom: -10px !important;
    font-weight: bold;
  }

  #main-slogan {
    color: #f15921;
    font-size: 25px !important;
  }

  .slogans>h1>.quote {
    font-size: 16px;
    color: black;
  }

  .slogans>h3 {
    font-size: 15px;
    margin-bottom: -10.646464px !important;
  }

  .slogans_home {
    font-size: 18px;
  }

  #column {
    display: block;
    width: 49.94944%;
  }

  #seeAllBooks {
    background-color: #757272;
    border-radius: 2rem;
    color: #fff;
    height: 3rem;
    width: 10rem;
    font-size: 13px;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
  }

  .labels>.label {
    display: block;
    text-align: center !important;
    background-color: #1d82aa !important;
  }

  .card-mage-cover {
    position: relative;
    height: 200px !important;
    width: 100%;
    margin-left: -3px;
    object-fit: cover;
    margin-top: -80px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px gray;
  }

  #content {
    position: relative;
    max-height: auto !important;
    margin-top: 5px;
  }

  #home-rows {
    margin-left: -20px;
  }

  /* =================Footer======================== */
  #footer-logo {
    width: 60px !important;
    margin-left: 10%;
  }

  .footerGrid>p>a {
    font-size: 12px;
    margin-bottom: 1px;
    padding: 2px;
  }

  .footerGrid>p>a:hover {
    color: #f15921 !important;
    transition: 0.2s ease-in-out;
    /* border-bottom: 1px solid #f15921 !important; */
  }

  #copyright {
    font-size: 13px !important;
    margin-left: 10px !important;
    margin-bottom: 5px;
  }

  .termsPrivacy>a {
    color: #d6cece;
    font-size: 12px !important;
    margin-right: 30px;
  }

  .termsPrivacy>a:hover {
    color: white !important;
  }

  /* ================Start oF single Page ======== */
  #Button>.icon {
    display: none;
  }

  #price,
  #price-rw {
    color: #f15921 !important;
    margin-top: 20px !important;
  }

  #price>span {
    color: #f15921 !important;
    margin-top: 20px !important;
    font-size: 30px !important;
  }

  #btn_pay {
    width: 25rem !important;
  }

  #single_btn {
    width: 100% !important;
    margin-top: 10px !important;
  }

  #audio_song {
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #f15921 !important;
    border-radius: 1px !important;
    height: 40px !important;
  }

  /* ====================Dashobooard============= */
  #dashboard-column-left {
    width: 40% !important;
    display: block;
  }

  #dash-card-link {
    font-size: 10px !important;
  }

  #dashboard-column-right {
    width: 55.99% !important;
    display: block !important;
  }

  .statistic>.icon {
    display: none;
  }

  #dashbCardUser,
  #dashbCardBook {
    display: inline-block;
    width: 46% !important;
  }

  #dashbCardUser:hover {
    margin-right: 1px;
  }

  #dashbCardBook:hover {
    margin-left: 1px;
  }

  .admin-tables {
    max-width: 100% !important;
    background-color: white;
    overflow-x: scroll;
    overflow-y: hidden;
    display: block;
  }

  #my_books {
    font-size: 18px !important;
  }

  .saveAsButtons {
    margin-left: 7%;
    margin-top: -20px !important;
  }

  .saveAsButtons>button {
    display: inline-block;
    width: 40% !important;
    /* margin-bottom: 10px !important; */
  }

  /* ====================End Dashobooard============= */
}

/* small screen */
@media only screen and (min-width: 385px) and (max-width: 776px) {
  .addMobileAppLinks {
    margin-right: -100px !important;
  }

  #mobile-app {
    width: 150px;
    min-width: 150px;
  }

  .header1 {
    /* margin-bottom: 30px; */
    background-color: #37302d;
    background-image: linear-gradient(to top, #0c0c0c, #563636);
    /* margin-bottom: 20px; */
    color: #fff;
    height: 80px;
    width: auto !important;
    display: block;
    position: flex;
    padding: 4px;
  }

  /* Start of Notification messages */
  .notification-show {
    margin-right: 1px !important;
    position: relative;
  }

  #notification-bell {
    font-size: 25px;
    margin-right: 1px !important;
    margin-left: -10px !important;
  }

  .notification-column-opened {
    padding: 8px;
    font-size: 16px;
  }

  /* End of Notification messages */

  #logo-header {
    width: 40px !important;
  }

  #header-link-item {
    font-size: 16px !important;
    margin-left: -25px !important;
  }

  #header-link-item>.menu>a>.item:hover {
    color: #f15921 !important;
  }

  .dropdown {
    font-size: 16px !important;
  }

  #logout {
    font-size: 10px !important;
  }

  #home-row {
    height: auto;
    margin-bottom: 50px;
    display: block;
  }

  .SearchBtn {
    display: none;
  }

  #btn_phone_btn {
    margin-left: 10%;
    display: block;
  }

  /* .SelectLanguage {
    display: none !important;
  } */

  #footer-language-show {
    display: block !important;
  }

  #phone-header-language-show {
    display: block !important;
    margin-top: -11px !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #right-auth {
    margin-right: 10px !important;
  }

  #profile-picture {
    width: 30px !important;
    height: 30px !important;
  }

  #underHeader {
    width: 90% !important;
  }

  #right-auth>.item>a>.icon {
    display: none !important;
  }

  #slogan {
    font-size: 14px !important;
  }

  .circle-btn {
    width: 30px;
    height: 30px;
  }

  .cartBadge {
    background-color: #f15921;
    width: 20px !important;
    height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: -35px;
    margin-left: -25px;
    z-index: 1;
    cursor: pointer;
  }

  .cartCount {
    font-size: 12px;
    color: white;
  }

  .item {
    color: rgb(238, 238, 228) !important;
    font-size: 8px;
    font-weight: normal !important;
  }

  .item>a,
  .item>b {
    font-size: 10px !important;
  }

  .item>a,
  .item>b {
    font-size: 16px;
  }

  a>.item {
    color: black !important;
    font-weight: normal;
    font-size: 10px;
  }

  a>.item:hover {
    color: black !important;
    font-weight: normal;
    font-size: 10px;
  }

  .right-auth-menu>a>.item {
    color: white !important;
    margin-bottom: 5px !important;
  }

  #covers {
    display: none;
  }

  #phone_covers {
    display: block;
    width: 100% !important;
    object-fit: cover;
    margin-bottom: 10px !important;
  }

  .slogans {
    margin-top: 5px !important;
    display: block !important;
    width: 100% !important;
  }

  .slogans>h1 {
    font-size: 20px;
    margin-bottom: -10px !important;
    font-weight: bold;
  }

  #main-slogan {
    color: #f15921;
    font-size: 22px !important;
  }

  .slogans>h1>.quote {
    font-size: 10px;
    display: none;
  }

  .slogans>h3 {
    font-size: 14px;
    margin-bottom: -10.646464px !important;
  }

  .slogans_home {
    font-size: 15px;
  }

  #column {
    display: block;
    width: 100%;
  }

  #seeAllBooks {
    background-color: #757272;
    border-radius: 2rem;
    color: #fff;
    height: 3rem;
    width: 10rem;
    font-size: 13px;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
  }

  .labels>.label {
    font-size: 12px !important;
    display: block;
    text-align: center !important;
    background-color: #1d82aa !important;
  }

  .card-mage-cover {
    position: relative;
    height: 200px !important;
    width: 100%;
    object-fit: cover;
    margin-top: -80px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px gray;
  }

  #content {
    position: relative;
    max-height: auto !important;
    margin-top: 5px;
  }

  #home-rows {
    margin-left: -20px;
  }

  /* =================Footer======================== */
  .footer1 {
    height: auto !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #footer-column {
    display: block;
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  #footer-logo {
    width: 40px !important;
    margin-left: 40%;
    margin-bottom: -20px !important;
  }

  #footer-down-column {
    width: 33.1% !important;
  }

  #footer-down-column>h3 {
    font-size: 16px !important;
  }

  .footerGrid {
    margin-top: 10px;
  }

  .footerGrid>p>a {
    font-size: 11px;
    margin-bottom: 1px;
    padding: 3px;
  }

  .footerGrid>p>a:hover {
    color: #f15921 !important;
    transition: 0.2s ease-in-out;
    /* border-bottom: 1px solid #f15921 !important; */
  }

  .footerGrid>p>a>.icon {
    display: none;
  }

  #copyright {
    font-size: 12px !important;
    margin-left: 10px !important;
    margin-bottom: 5px;
  }

  .termsPrivacy>a {
    color: #d6cece;
    font-size: 12px !important;
    margin-left: 20px;
  }

  .termsPrivacy>a:hover {
    color: white !important;
  }

  /* ================Start oF single Page ======== */
  .buy_content {
    margin-top: 20px;
    /* background-color: white !important; */
    /* box-shadow: 0px 0px 5px 2px gray; */
    padding: 5px;
    margin-bottom: 30px;
  }

  #report-column {
    display: block;
    width: 80% !important;
    margin-bottom: 20px !important;
    margin-left: 20% !important;
    margin-top: 10px;
    height: 40px !important;
  }

  #Button>.icon {
    display: none;
  }

  #price,
  #price-rw {
    color: #f15921 !important;
    margin-top: 20px !important;
  }

  #price>span {
    color: #f15921 !important;
    margin-top: 20px !important;
    font-size: 30px !important;
  }

  #audio_song {
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #f15921 !important;
    border-radius: 30px !important;
    height: 50px !important;
    width: 100% !important;
  }

  #btn_pay {
    width: 25rem !important;
  }

  /* ================End oF single Page ======== */

  /* ====================Dashobooard============= */
  #dashboard-column-left {
    width: 40% !important;
    display: block;
  }

  #dash-card-link {
    font-size: 10px !important;
  }

  #dashboard-column-right {
    width: 55.99% !important;
    display: block !important;
  }

  .statistic>.icon {
    display: none;
  }

  #dashbCardUser,
  #dashbCardBook {
    display: inline-block;
    width: 46% !important;
  }

  #dashbCardUser:hover {
    margin-right: 1px;
  }

  #dashbCardBook:hover {
    margin-left: 1px;
  }

  .admin-tables {
    max-width: 100% !important;
    background-color: white;
    overflow-x: scroll;
    overflow-y: hidden;
    display: block;
  }

  #my_books {
    font-size: 14px !important;
  }

  #create-book-column {
    display: block !important;
    width: 100% !important;
  }

  .form-container>h2 {
    /* font-size: 18px !important; */
    color: #f15921;
  }

  .Separator {
    width: 70% !important;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 45%;
  }

  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 40px !important;
    height: 40px !important;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #f15921;
    border-color: gray transparent #f15921 transparent;
    animation: lds-dual-ring 0.2s linear infinite;
  }

  .saveAsButtons {
    margin-left: 7%;
    margin-top: -20px !important;
  }

  .saveAsButtons>button {
    display: inline-block;
    width: 40% !important;
    /* margin-bottom: 10px !important; */
  }

  /* ====================End Dashobooard============= */
}

/* Tiny devices  */
@media only screen and (min-width: 330px) and (max-width: 385px) {
  .addMobileAppLinks {
    margin-left: -95% !important;
  }

  #mobile-app {
    width: 150px;
    min-width: 150px;
  }

  .header1 {
    /* margin-bottom: 30px; */
    background-color: #37302d;
    background-image: linear-gradient(to top, #0c0c0c, #563636);
    /* margin-bottom: 20px; */
    color: #fff;
    height: 70px;
    width: auto !important;
    display: block;
    position: flex;
    padding: 4px !important;
  }

  /* Start of Notification messages */
  .notification-show {
    margin-right: 2px !important;
    position: relative;
  }

  #notification-bell {
    font-size: 25px;
    margin-right: 1px !important;
    margin-left: -20px !important;
  }

  .notification-column-opened {
    padding: 8px;
    font-size: 15px;
  }

  /* End of Notification messages */

  #logo-header {
    width: 25px !important;
  }

  #header-link-item {
    font-size: 15px !important;
    margin-left: -26px !important;
  }

  #header-link-item>.menu>a>.item:hover {
    color: #f15921 !important;
  }

  .dropdown {
    font-size: 14px !important;
  }

  #logout {
    font-size: 12px !important;
  }

  #home-row {
    height: auto;
    margin-bottom: 30px !important;
    display: block;
  }

  .SearchBtn {
    display: none;
  }

  #btn_phone_btn {
    margin-left: -38px !important;
    display: block;
  }

  /* .SelectLanguage {
    display: none !important;
  } */

  #footer-language-show {
    display: block !important;
  }

  #phone-header-language-show {
    display: block !important;
    margin-top: -11px !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #right-auth {
    margin-left: -25px !important;
  }

  #right-auth>.item>a {
    margin-left: -2.75777px !important;
  }

  #right-auth>.item>a>.icon {
    display: none !important;
  }

  .right-auth-menu2 {
    margin-left: 10px !important;
  }

  #profile-picture {
    /* width: 25px !important;
    height: 25px !important; */
    display: none !important;
  }

  #underHeader {
    width: 90% !important;
  }

  #slogan {
    font-size: 14px !important;
  }

  .circle-btn {
    width: 25px;
    height: 25px;
  }

  .cartBadge {
    background-color: #f15921;
    width: 17px !important;
    height: 17px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: -35px;
    margin-left: -25px;
    z-index: 1;
    cursor: pointer;
  }

  .cartCount {
    font-size: 10px;
    color: white;
  }

  .item {
    color: rgb(238, 238, 228) !important;
    font-size: 8px;
    font-weight: normal !important;
  }

  .item>a,
  .item>b {
    font-size: 10px !important;
  }

  .item>a,
  .item>b {
    font-size: 16px;
  }

  a>.item {
    color: black !important;
    font-weight: normal;
    font-size: 10px;
  }

  a>.item:hover {
    color: black !important;
    font-weight: normal;
    font-size: 10px;
  }

  .right-auth-menu>a>.item {
    color: white !important;
    margin-bottom: 5px !important;
  }

  #covers {
    display: none;
  }

  #phone_covers {
    display: block;
    width: 100% !important;
    object-fit: cover;
    margin-bottom: 10px !important;
  }

  .slogans {
    margin-top: 5px !important;
    display: block !important;
    width: 100% !important;
  }

  .slogans>h1 {
    font-size: 20px;
    margin-bottom: -10px !important;
    font-weight: bold;
  }

  #main-slogan {
    color: #f15921;
    font-size: 22px !important;
  }

  .slogans>h1>.quote {
    font-size: 10px;
    display: none;
  }

  .slogans>h3 {
    font-size: 14px;
    margin-bottom: -10.646464px !important;
  }

  .slogans_home {
    font-size: 15px;
  }

  #column {
    display: block;
    width: 100%;
  }

  #seeAllBooks {
    background-color: #757272;
    border-radius: 2rem;
    color: #fff;
    height: 3rem;
    width: 10rem;
    font-size: 13px;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
  }

  .labels>.label {
    font-size: 12px !important;
    display: block;
    text-align: center !important;
    background-color: #1d82aa !important;
  }

  .card-mage-cover {
    position: relative;
    height: 200px !important;
    width: 100%;
    object-fit: cover;
    margin-top: -80px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px gray;
  }

  #content {
    position: relative;
    max-height: auto !important;
    margin-top: 5px;
  }

  #home-rows {
    margin-left: -20px;
  }

  #cart-button-small {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
  }

  #ButtonCartHome,
  #ButtonBuyHome {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
  }

  #single_btn {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }

  #report-column {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }

  #delete-cart-small {
    padding-right: 1px !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    /* margin-left: -60px !important; */
  }

  /* =================Footer======================== */
  .footer1 {
    height: auto !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #footer-column {
    display: block;
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  #footer-logo {
    width: 50px !important;
    margin-left: 38%;
    margin-bottom: -20px !important;
  }

  #footer-down-column {
    width: 33.1% !important;
  }

  #footer-down-column>h3 {
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .footerGrid {
    margin-top: 10px;
  }

  .footerGrid>p>a {
    font-size: 10px;
    margin-bottom: 1px;
    padding: 3px;
  }

  .footerGrid>p>a:hover {
    color: #f15921 !important;
    transition: 0.2s ease-in-out;
    /* border-bottom: 1px solid #f15921 !important; */
  }

  .footerGrid>p>a>.icon {
    display: inline-block;
    font-size: 10px !important;
  }

  #copyright {
    font-size: 10px !important;
    margin-left: 10px !important;
    margin-bottom: 5px;
  }

  .termsPrivacy>a {
    color: #d6cece;
    font-size: 12px !important;
    margin-left: 15px;
  }

  .termsPrivacy>a:hover {
    color: white !important;
  }

  /* ================Start oF single Page ======== */
  .buy_content {
    margin-top: 20px;
    /* background-color: white !important;
    box-shadow: 0px 0px 5px 2px gray; */
    padding: 5px;
    margin-bottom: 30px;
  }

  #report-column {
    display: block;
    width: 80% !important;
    margin-bottom: 20px !important;
    margin-left: 20% !important;
    margin-top: 10px;
    height: 40px !important;
  }

  #Button>.icon {
    display: none;
  }

  #price,
  #price-rw {
    color: #f15921 !important;
    margin-top: 20px !important;
  }

  #price>span {
    color: #f15921 !important;
    margin-top: 20px !important;
    font-size: 30px !important;
  }

  #audio_song {
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #f15921 !important;
    border-radius: 30px !important;
    height: 50px !important;
    width: 100% !important;
  }

  #btn_pay {
    width: 100% !important;
  }

  /* ================End oF single Page ======== */

  /* ====================Dashobooard============= */
  #dashboard-column-left {
    width: 100% !important;
    display: block;
  }

  #dash-card-link {
    font-size: 10px !important;
  }

  #dashboard-column-right {
    width: 100% !important;
    display: block !important;
  }

  .statistic>.icon {
    display: none;
  }

  #dashbCardUser,
  #dashbCardBook {
    display: inline-block;
    width: 100% !important;
  }

  #dashbCardUser:hover {
    margin-right: 1px;
  }

  #dashbCardBook:hover {
    margin-left: 1px;
  }

  .admin-tables {
    max-width: 100% !important;
    background-color: white;
    overflow-x: scroll;
    overflow-y: hidden;
    display: block;
  }

  #my_books {
    font-size: 14px !important;
  }

  #create-book-column {
    display: block !important;
    width: 100% !important;
  }

  .form-container>h2 {
    font-size: 18px !important;
    color: #f15921;
  }

  .Separator {
    width: 80% !important;
  }

  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 30px !important;
    height: 30px !important;
    margin: 6px;
    border-radius: 50%;
    border: 3px solid #f15921;
    border-color: gray transparent #f15921 transparent;
    animation: lds-dual-ring 0.2s linear infinite;
  }

  .saveAsButtons {
    margin-left: 1%;
    margin-top: -20px !important;
  }

  .saveAsButtons>button {
    display: block;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

/* Very Tiny devices  */
@media only screen and (max-width: 330px) {
  .addMobileAppLinks {
    margin-left: -95% !important;
  }

  #mobile-app {
    width: 150px;
    min-width: 150px;
  }

  .header1 {
    /* margin-bottom: 30px; */
    background-color: #37302d;
    background-image: linear-gradient(to top, #0c0c0c, #563636);
    /* margin-bottom: 20px; */
    color: #fff;
    height: 70px;
    /* width:  337px !important; */
    display: block !important;
    /* position: flex !important; */
    padding: 5px !important;
    zoom: normal !important;
  }

  .App {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    /* font-family: 'ABeeZee', sans-serif; */
    background-color: #eeeeee;
    width: 337px !important;
  }

  .right-auth-menu {
    background-color: #101010 !important;
    background-image: linear-gradient(to bottom, #101010, #2b2020) !important;
    border: 1px solid #eeeeee !important;
    width: 250px;
    margin-top: 10px !important;
  }

  /* Start of Notification messages */
  .notification-show {
    margin-right: 1px !important;
    position: relative;
  }

  #notification-bell {
    font-size: 25px;
    margin-right: 1px !important;
    margin-left: -20px !important;
  }

  .notification-column-opened {
    padding: 8px;
    font-size: 15px;
  }

  /* End of Notification messages */

  #logo-header {
    width: 25px !important;
  }

  #header-link-item {
    font-size: 15px !important;
    margin-left: -26px !important;
  }

  #header-link-item>.menu>a>.item:hover {
    color: #f15921 !important;
  }

  .dropdown {
    font-size: 14px !important;
  }

  #logout {
    font-size: 12px !important;
  }

  #home-row {
    height: auto;
    margin-bottom: 30px !important;
    display: block;
  }

  #btn_phone_btn {
    margin-left: -38px !important;
    display: block;
  }

  /* .SelectLanguage {
    display: none !important;
  } */

  #footer-language-show {
    display: block !important;
  }

  #phone-header-language-show {
    display: block !important;
    margin-top: -11px !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #right-auth {
    margin-left: -25px !important;
  }

  #right-auth>.item>a {
    margin-left: -2.75777px !important;
  }

  #right-auth>.item>a>.icon {
    display: none !important;
  }

  .right-auth-menu2 {
    margin-left: 10px !important;
  }

  #profile-picture {
    /* width: 25px !important;
    height: 25px !important; */
    display: none !important;
  }

  #underHeader {
    width: 90% !important;
  }

  #slogan {
    font-size: 14px !important;
  }

  .circle-btn {
    width: 25px;
    height: 25px;
  }

  .cartBadge {
    background-color: #f15921;
    width: 17px !important;
    height: 17px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: -35px;
    margin-left: -25px;
    z-index: 1;
    cursor: pointer;
  }

  .cartCount {
    font-size: 10px;
    color: white;
  }

  .item {
    color: rgb(238, 238, 228) !important;
    font-size: 8px;
    font-weight: normal !important;
  }

  .item>a,
  .item>b {
    font-size: 10px !important;
  }

  .item>a,
  .item>b {
    font-size: 16px;
  }

  a>.item {
    color: black !important;
    font-weight: normal;
    font-size: 10px;
  }

  a>.item:hover {
    color: black !important;
    font-weight: normal;
    font-size: 10px;
  }

  .right-auth-menu>a>.item {
    color: white !important;
    margin-bottom: 5px !important;
  }

  #covers {
    display: none;
  }

  #phone_covers {
    display: block;
    width: 100% !important;
    object-fit: cover;
    margin-bottom: 10px !important;
  }

  .slogans {
    margin-top: 5px !important;
    display: block !important;
    width: 100% !important;
  }

  .slogans>h1 {
    font-size: 20px;
    margin-bottom: -10px !important;
    font-weight: bold;
  }

  #main-slogan {
    color: #f15921;
    font-size: 22px !important;
  }

  .slogans>h1>.quote {
    font-size: 10px;
    display: none;
  }

  .slogans>h3 {
    font-size: 14px;
    margin-bottom: -10.646464px !important;
  }

  .slogans_home {
    font-size: 15px;
  }

  #column {
    display: block;
    width: 100%;
  }

  #seeAllBooks {
    background-color: #757272;
    border-radius: 2rem;
    color: #fff;
    height: 3rem;
    width: 10rem;
    font-size: 13px;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
  }

  .labels>.label {
    font-size: 12px !important;
    display: block;
    text-align: center !important;
    background-color: #1d82aa !important;
  }

  .card-mage-cover {
    position: relative;
    height: 200px !important;
    width: 100%;
    object-fit: cover;
    margin-top: -80px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px gray;
  }

  #content {
    position: relative;
    max-height: auto !important;
    margin-top: 5px;
  }

  #home-rows {
    margin-left: -20px;
  }

  #cart-button-small {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
  }

  #ButtonCartHome,
  #ButtonBuyHome {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
  }

  #single_btn {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }

  #report-column {
    display: block !important;
    width: 100% !important;
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }

  #delete-cart-small {
    padding-right: 1px !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    /* margin-left: -60px !important; */
  }

  /* =================Footer======================== */
  .footer1 {
    height: auto !important;
    background-color: #2e2e2e;
    background-image: linear-gradient(to bottom, #101010, #2b2020);
  }

  #footer-column {
    display: block;
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  #footer-logo {
    width: 50px !important;
    margin-left: 38%;
    margin-bottom: -20px !important;
  }

  #footer-down-column {
    width: 33.1% !important;
  }

  #footer-down-column>h3 {
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .footerGrid {
    margin-top: 10px;
  }

  .footerGrid>p>a {
    font-size: 10px;
    margin-bottom: 1px;
    padding: 3px;
  }

  .footerGrid>p>a:hover {
    color: #f15921 !important;
    transition: 0.2s ease-in-out;
    /* border-bottom: 1px solid #f15921 !important; */
  }

  .footerGrid>p>a>.icon {
    display: inline-block;
    font-size: 10px !important;
  }

  #copyright {
    font-size: 10px !important;
    margin-left: 10px !important;
    margin-bottom: 5px;
  }

  .termsPrivacy>a {
    color: #d6cece;
    font-size: 12px !important;
    margin-left: 15px;
  }

  .termsPrivacy>a:hover {
    color: white !important;
  }

  /* ================Start oF single Page ======== */
  .buy_content {
    margin-top: 20px;
    /* background-color: white !important;
    box-shadow: 0px 0px 5px 2px gray; */
    padding: 5px;
    margin-bottom: 30px;
  }

  #report-column {
    display: block;
    width: 80% !important;
    margin-bottom: 20px !important;
    margin-left: 20% !important;
    margin-top: 10px;
    height: 40px !important;
  }

  #Button>.icon {
    display: none;
  }

  #price,
  #price-rw {
    color: #f15921 !important;
    margin-top: 20px !important;
  }

  #price>span {
    color: #f15921 !important;
    margin-top: 20px !important;
    font-size: 30px !important;
  }

  #audio_song {
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #f15921 !important;
    border-radius: 30px !important;
    height: 50px !important;
    width: 100% !important;
  }

  #btn_pay {
    width: 100% !important;
  }

  /* ================End oF single Page ======== */

  /* ====================Dashobooard============= */
  #dashboard-column-left {
    width: 100% !important;
    display: block;
  }

  #dash-card-link {
    font-size: 10px !important;
  }

  #dashboard-column-right {
    width: 100% !important;
    display: block !important;
  }

  .statistic>.icon {
    display: none;
  }

  #dashbCardUser,
  #dashbCardBook {
    display: inline-block;
    width: 100% !important;
  }

  #dashbCardUser:hover {
    margin-right: 1px;
  }

  #dashbCardBook:hover {
    margin-left: 1px;
  }

  .admin-tables {
    max-width: 100% !important;
    background-color: white;
    overflow-x: scroll;
    overflow-y: hidden;
    display: block;
  }

  #my_books {
    font-size: 14px !important;
  }

  #create-book-column {
    display: block !important;
    width: 100% !important;
  }

  .form-container>h2 {
    font-size: 18px !important;
    color: #f15921;
  }

  .Separator {
    width: 80% !important;
  }

  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 30px !important;
    height: 30px !important;
    margin: 6px;
    border-radius: 50%;
    border: 3px solid #f15921;
    border-color: gray transparent #f15921 transparent;
    animation: lds-dual-ring 0.2s linear infinite;
  }

  .saveAsButtons {
    margin-left: 1%;
    margin-top: -20px !important;
  }

  .saveAsButtons>button {
    display: block;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.s-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-main-color {
  color: #ff4500 !important;
}

.s-bg-color {
  background-color: #ff4500 !important;
}

.s-white {
  color: #fff !important;
}

.s-grey {
  color: #beb7b5;
}

.s-mt {
  margin-top: 10rem !important;
}

.s-mb {
  margin-bottom: 10rem !important;
}

.s-big-size {
  font-size: 2.1rem;
}

.s-small-size {
  font-size: 1.2rem;
}

.s-card {
  border: #beb7b5 1px solid;
  padding: 10px;
  margin: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.s-bold {
  font-weight: bold;
}

/* .text,#header-link-item .item .text,#flag .visible .item .text{
  color: #000 !important;
}

#header-link-item .text,#flag .text{
  color: #fff !important;
} */

.back-home {
  padding: 10px;
  border: #beb7b5 1px solid;
  font-size: 1.2rem;
  border-radius: 5px;
  color: #beb7b5;
  background-color: rgba(255, 255, 255, 0);
}

/* End Resposivines */

.table-row-dashb:hover {
  background-color: rgb(235, 229, 229) !important;
}

.Separator {
  width: 20%;
  height: 3px;
  background-color: #f15921;
  margin-bottom: 30px;
}

.Separator:hover,
.Separator:focus {
  width: 50%;
  transition: 0.7s ease-in-out all;
}

.contactLink {
  padding: 3px;
}

.contactLink:hover {
  padding: 3px;
  border-bottom: 2px solid #f15921;
}

.faq-accordion {
  width: 100%;
  background-color: white;
  padding: 18px;
  font-size: 17px;
}

.custom-color .visible .menu .item .text {
  color: #000 !important;
}

@media (min-width: 575.98px) {
  .haapa-text{
    font-size: 4.1rem;
  }

  .paid-books-box {
    margin-top: 6rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }

  .paid-books {
    border: 1px solid rgb(204, 204, 204);
    margin-bottom: 9rem;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    align-items: center;
  }

  .paid-books-h3 {
    /* margin-left: 15%; */
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .paid-books .btn-delete {
    margin: -15px 0 0 8rem;
    z-index: 1;
    position: absolute;
  }

  .paid-books .content-with-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
}

@media (max-width: 575.98px) {

  .haapa-text{
    font-size: 3.5rem;
  }

  .d-flex-column {
    flex-direction: column;
  }

  .paid-books-box {
    margin: 5%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }

  .paid-books-h3 {
    margin-left: 2rem;
    margin-bottom: 6rem;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .paid-books {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    margin-bottom: 10rem;
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    align-items: center;
  }

  .paid-books .btn-delete {
    margin: -15px 0 0 8rem;
    z-index: 1;
    position: absolute;
  }

  .paid-books .content-with-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
}

.record-btn,
.stop-record-btn {
  width: 100%;
  height: 40px;
  /* margin-left: 9.88% !important; */
  text-transform: uppercase !important;
}

.record-btn {
  background: #4caf50 none !important;
  color: #fff !important;
}

.record-btn:hover {
  padding: 0.8em 1.7em 0.8em !important;
  transition: padding 0.5s ease-in !important;
  background: #3b813e none !important;
}

.stop-record-btn,
.stop-record-btn:hover {
  background: #f44336 none !important;
  color: #fff !important;
}

.start-record-icon {
  font-size: 18px !important;
}

.record-icon {
  animation: pulse 1s infinite;
  font-size: 18px !important;
}

@keyframes pulse {
  10% {
    transform: scale(2);
  }
}

.record-video-btn,
.stop-video-record-btn {
  width: 100%;
  height: 50px;
  /* margin-left: 9.88% !important; */
  text-transform: uppercase !important;
}

.record-video-btn {
  background: #000000 none !important;
  color: #fff !important;
}

.record-video-btn:hover {
  padding: 0.8em 1.7em 0.8em !important;
  transition: padding 0.5s ease-in !important;
  background: #242323 none !important;
}

.stop-video-record-btn,
.stop-video-record-btn:hover {
  background: #f15921 none !important;
  color: #fff !important;
}

.start-record-icon {
  font-size: 18px !important;
}

.record-icon {
  animation: pulse 1s infinite;
  font-size: 18px !important;
}

@keyframes pulse {
  10% {
    transform: scale(2);
  }
}
